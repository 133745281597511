<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3
          data-aos="fade-right"
          class="mb-4 header-spacing moving-gradient-text fw-bolder"
        >
          WHY FRIDAY?
        </h3>
        <p>Well, who doesn't like Fridays?</p>

        <router-link to="/" class="btn moving-gradient-button"
          ><i class="far fa-home me-2"></i>Home</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
